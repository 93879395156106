import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import ReclameAquiAward from 'components/ReclameAquiAward'

export const Container = styled.div`
  align-items: flex-end;
  display: flex;
  grid-area: award;
`

export const DefaultReclameAquiAward = styled(ReclameAquiAward)`
  align-items: center;

  p {
    color: ${selectTheme('colors.neutral.60')};

    ${media.up('4xl')} {
      max-width: 360px;
    }
  }
`
