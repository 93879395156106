import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

export const Container = styled.ul`
  grid-area: products;
  display: flex;

  ${media.down('4xl')} {
    overflow: auto;
    padding-top: ${selectTheme('spacingY.8xl')};
    padding-bottom: ${selectTheme('spacingY.8xl')};
    margin-left: -${selectTheme('spacingX.5xl')};
    margin-right: -${selectTheme('spacingX.5xl')};
    padding-left: ${selectTheme('spacingX.5xl')};
    padding-right: ${selectTheme('spacingX.5xl')};
  }
`
