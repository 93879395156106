import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as DefaultContainer } from 'components/Container'
import { Grid as DefaultGrid } from 'components/Grid'

export const Section = styled.section`
  overflow: visible;
`

export const Container = styled(DefaultContainer)`
  padding-top: ${selectTheme('spacingY.6xl')};
  padding-bottom: ${selectTheme('spacingY.6xl')};

  ${media.up('5xl')} {
    padding-top: ${selectTheme('spacingBottom.8xl')};
    padding-bottom: ${selectTheme('spacingBottom.21xl')};
  }
`

export const Grid = styled(DefaultGrid)`
  grid-template-rows: max-content;
  grid-template-areas:
    '${'info '.repeat(4)}'
    '${'products '.repeat(4)}'
    '${'award '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'info '.repeat(8)}'
      '${'products '.repeat(8)}'
      '${'award '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas:
      '${'info '.repeat(5)} . ${' products'.repeat(6)}'
      '${'award '.repeat(5)} . ${' products'.repeat(6)}';
  }
`
