import { string } from 'prop-types'

import { RichText } from './styles'

const propTypes = {
  children: string,
}

const Description = ({ children }) => {
  if (!children) return null

  return (
    <RichText
      as="div"
      variant={{ _: 'bodyXsRegular', '5xl': 'bodySmRegular' }}
      color="neutral.60"
      data-testid="product-card-body"
    >
      {children}
    </RichText>
  )
}

Description.propTypes = propTypes
export default Description
