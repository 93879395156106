import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

import DefaultRichText from 'components/RichText'

export const RichText = styled(DefaultRichText)`
  margin-top: ${selectTheme('spacingTop.6xl')};
  white-space: pre-line;

  > p {
    margin-bottom: ${selectTheme('spacingBottom.md')};
  }

  b {
    display: block;
    color: ${selectTheme('colors.neutral.90')};
  }
`
