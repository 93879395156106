import { node, string } from 'prop-types'

import { DefaultButtonCta } from './styles'

const propTypes = {
  children: node,
  label: string,
}

const ButtonCta = ({ children, label }) => {
  if (!children) return null

  return (
    <DefaultButtonCta
      size="medium"
      label={label}
      data-testid="product-card-button"
      as="div"
    >
      {children}
    </DefaultButtonCta>
  )
}

ButtonCta.propTypes = propTypes
export default ButtonCta
