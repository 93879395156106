import { bool } from 'prop-types'

import { Container, DefaultReclameAquiAward } from './styles'

const propTypes = {
  show: bool,
}

const ReclameAquiAward = ({ show }) => {
  if (show === false) return null

  return (
    <Container data-testid="section-hero-products-awards">
      <DefaultReclameAquiAward />
    </Container>
  )
}

ReclameAquiAward.propTypes = propTypes
export default ReclameAquiAward
