import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

import { LazyImage as DefaultLazyImage } from 'components/LazyImage'

export const LazyImage = styled(DefaultLazyImage)`
  width: 64px;
  height: 64px;
  margin-bottom: ${selectTheme('spacingBottom.10xl')};
  img,
  svg {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`
