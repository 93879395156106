import { object, string } from 'prop-types'

import { LazyImage } from './styles'

const propTypes = {
  description: string,
  icon: object,
}

const Image = ({ description, icon }) => {
  if (!icon) return null

  return (
    <LazyImage
      className="icon"
      alt={description || ''}
      aspectRatio={1}
      {...icon}
    />
  )
}

Image.propTypes = propTypes
export default Image
