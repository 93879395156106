import { node, object } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  children: node,
  currentSectionRef: object,
}

const defaultProps = {}

const Wrapper = ({ children, currentSectionRef, ...others }) => {
  return (
    <Section
      {...others}
      ref={currentSectionRef}
      data-testid="section-hero-products-wrapper"
    >
      <Container>
        <Grid>{children}</Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes
Wrapper.defaultProps = defaultProps

export default Wrapper
