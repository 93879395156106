import { ButtonPrimaryTextOnly } from '@creditas-ui/button'
import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Cta as CtaComponent } from 'components/Cta'
import { LazyImage } from 'components/LazyImage'

export const Container = styled.li`
  flex-basis: 236px;
  flex-grow: 0;

  background-color: ${selectTheme('colors.neutral.0')};
  border-radius: ${selectTheme('radius.6xl')};
  box-shadow: ${selectTheme('shadow.sm')};
  list-style: none;

  transition: box-shadow 0.3s ease-in-out;

  &:first-of-type {
    margin-right: ${selectTheme('spacingRight.3xl')};
  }

  ${media.up('5xl')} {
    flex-basis: 288px;
    box-shadow: ${selectTheme('shadow.lg')};

    &:first-of-type {
      margin-right: ${selectTheme('spacingRight.6xl')};
    }
  }

  ${media.down('2xl')} {
    flex-shrink: 0;
  }
`

export const Cta = styled(CtaComponent)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  padding: ${selectTheme('spacingY.6xl')} ${selectTheme('spacingX.3xl')};

  text-decoration: none;

  cursor: pointer;

  ${media.up('5xl')} {
    padding: ${selectTheme('spacingX.6xl')};
  }
`

export const ButtonCta = styled(ButtonPrimaryTextOnly)`
  background-color: ${selectTheme('colors.primary.40')};
`

export const Image = styled(LazyImage)`
  width: 64px;
  height: 64px;
  margin-bottom: ${selectTheme('spacingBottom.10xl')};
  img,
  svg {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`

export const Info = styled.div`
  margin-bottom: ${selectTheme('spacingBottom.8xl')};

  ${media.up('5xl')} {
    margin-bottom: ${selectTheme('spacingBottom.10xl')};
  }
`
