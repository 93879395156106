import { array } from 'prop-types'

import Card from './components/Card'

import { Container } from './styles'

const propTypes = {
  productCards: array,
}

const CardList = ({ productCards }) => {
  if (!productCards?.length) return null

  return (
    <Container>
      {productCards?.map((card, index) => (
        <Card index={index} key={index} {...card} />
      ))}
    </Container>
  )
}

CardList.propTypes = propTypes
export default CardList
