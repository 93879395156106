import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: string,
}

const Description = ({ children }) => {
  if (!children) return null

  return (
    <Typography
      color="neutral.80"
      data-testid="subtitle"
      variant={{ _: 'bodyMdLight', '5xl': 'bodyXlLight' }}
    >
      {children}
    </Typography>
  )
}

Description.propTypes = propTypes
export default Description
