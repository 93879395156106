import { number, object, string } from 'prop-types'
import { navigate } from 'gatsby'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import ButtonCta from './components/ButtonCta'
import Description from './components/Description'
import Image from './components/Image'
import Title from './components/Title'

import { Container, Cta, Info } from './styles'

const propTypes = {
  analyticEvent: object,
  body: string,
  buttonCta: object,
  callToAction: string,
  callToActionLink: string,
  index: number,
  product: object,
  title: string,
  titleRichText: string,
}

const Card = ({
  analyticEvent: cardAnalyticEvent,
  body,
  buttonCta,
  callToAction,
  callToActionLink,
  index,
  product,
  title,
  titleRichText,
  ...others
}) => {
  const { triggerEvent } = useTriggerEvents()
  const ctaProps = {
    ...buttonCta,
    type: buttonCta?.actionType || 'link',
    label: buttonCta?.label || callToAction,
    url: buttonCta?.actionHref || callToActionLink,
  }

  const analyticEvent = {
    label: `${index + 1} | ${ctaProps.label || ctaProps.url}`,
    pageInteraction: ctaProps?.type !== 'link',
  }

  const handleClick = () => {
    triggerEvent(analyticEvent)

    return ctaProps.type === 'link' && ctaProps.url && navigate(ctaProps.url)
  }

  return (
    <Container data-testid="product-card" {...others}>
      <Cta onClick={handleClick} {...ctaProps}>
        <div>
          <Image
            description={product?.icon?.description}
            icon={product?.icon}
          />
          <Info>
            <Title>{titleRichText || title}</Title>
            <Description>{body}</Description>
          </Info>
        </div>
        <ButtonCta label={buttonCta?.label}>{ctaProps?.label}</ButtonCta>
      </Cta>
    </Container>
  )
}

Card.propTypes = propTypes

export default Card
