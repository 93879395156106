import { array, bool, object, oneOf, string } from 'prop-types'

import { InfoContainer } from './base.styles'
import CardList from './components/CardList'
import Description from './components/Description'
import ReclameAquiAward from './components/ReclameAquiAward'
import Title from './components/Title'
import Wrapper from './components/Wrapper'

const propTypes = {
  description: string,
  heroRef: object,
  productCards: array,
  showReclameAquiAward: bool,
  title: string,
  titleHeadingTag: oneOf(['h1', 'h2']),
}

const HeroProducts = ({
  description,
  heroRef,
  productCards,
  showReclameAquiAward,
  title,
  titleHeadingTag,
  ...others
}) => {
  return (
    <Wrapper {...others}>
      <InfoContainer>
        <Title as={titleHeadingTag || 'h1'}>{title}</Title>
        <Description>{description}</Description>
      </InfoContainer>
      <CardList productCards={productCards} />
      <ReclameAquiAward show={showReclameAquiAward} />
    </Wrapper>
  )
}

HeroProducts.propTypes = propTypes
export default HeroProducts
