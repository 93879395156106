import { string } from 'prop-types'

import RichText from 'components/RichText'

const propTypes = {
  children: string,
}

const Title = ({ children, ...others }) => {
  if (!children) return null

  return (
    <RichText
      variant="headingXsLight"
      color="neutral.90"
      data-testid="product-card-title"
      as="h3"
      {...others}
    >
      {children}
    </RichText>
  )
}

Title.propTypes = propTypes
export default Title
